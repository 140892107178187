import { Transfer as AntTransfer } from 'antd'
import { TransferItem, TransferProps } from 'antd/lib/transfer'
import classNames from 'classnames'
import './transfer.scss'

export const Transfer = ({ className, ...props }: TransferProps<TransferItem>) => {
  return (
    <AntTransfer className={classNames("dibn-transfer", className)} {...props} />
  )
}
