import { ReactNode } from 'react'
import classNames from 'classnames'
import { Input as AntdInput, InputProps } from 'antd/es'
import { Label } from '../label/label'
import './input.scss'

type TInput = InputProps & {
  label?: ReactNode
  error?: boolean
  errorText?: ReactNode
}

export const Input = ({ label, className, error, errorText, required, ...restProps }: TInput) => (
  <div
    className={classNames('dibn-input', className, {
      'input-error': !!error,
      'dibn-label': !!label
    })}
  >
    {!!label && <Label requireInput={required}>{label}</Label>}
    <AntdInput required={required} {...restProps} />
    {!!error && <p className="text-error">{errorText}</p>}
  </div>
)
