import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { PlusOutlined, WarningOutlined } from '@ant-design/icons'
import { HttpService } from 'services/http.service'
import { commonSlice, getUser, getWorkspaces } from 'store/commonSlice'
import { Button, message, Loader } from 'components/common'
import './workspacesSidebar.scss'

export const WorkspacesSidebar = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const user = useSelector(getUser)
  const workspaces = useSelector(getWorkspaces)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (workspaces?.length) return

    setIsLoading(true)

    HttpService.get('/workspaces/')
      .then(res => {
        dispatch(commonSlice.actions.setWorkspaces(res.data))
      })
      .catch(e => {
        setIsError(true)

        if (!e.isHandled) {
          message.error({
            content: 'An error occurred while fetching workspaces. Try to reload the page.'
          })
        }
      })
      .finally(() => setIsLoading(false))
  }, [dispatch, workspaces?.length])

  const changeActiveWorkspace = useCallback(
    (workspaceUuid: string) => {
      dispatch(commonSlice.actions.setActiveWorkspace(workspaceUuid))
    },
    [dispatch]
  )

  const _workspaces = useMemo(() => {
    if (isLoading) return <Loader size="large" />
    if (isError) return <WarningOutlined className="error-icon" />
    if (!workspaces || !user) return null

    return [...workspaces]
      .sort(w1 => {
        if (w1.uuid === user.activeWorkspace) return -1
        else return 1
      })
      .map(workspace => {
        const isActive = workspace.uuid === user.activeWorkspace

        return (
          <Button
            key={workspace.uuid}
            className={classNames('workspace', isActive && 'active')}
            type={isActive ? 'primary' : undefined}
            title={workspace.name}
            onClick={!isActive ? () => changeActiveWorkspace(workspace.uuid) : undefined}
          >
            {workspace.name}
          </Button>
        )
      })
  }, [workspaces, user, isLoading, isError, changeActiveWorkspace])

  return (
    <div className="workspaces-sidebar">
      <div className="title">WorkSpace</div>
      <div className="separator" />
      <div className="workspaces">{_workspaces}</div>
      {!isLoading && !isError && (
        <Button
          className="add-workspace"
          icon={<PlusOutlined />}
          href="https://rock15.com/request-digital-brain/"
          target="_blank"
        />
      )}
    </div>
  )
}
