import { useNavigate } from 'react-router-dom'
import { PATHS } from 'constants/routes'
import { AuthWrapper } from 'components/authWrapper/authWrapper'
import { Button, Input } from 'components/common'
import { useLoginForm } from './hooks'
import './login.scss'

export const LoginPage = (): JSX.Element => {
  const navigate = useNavigate()

  const {
    email,
    password,
    emailError,
    passwordError,
    loginError,
    isProcessing,
    setEmail,
    setPassword,
    logIn
  } = useLoginForm()

  return (
    <AuthWrapper>
      <div className="login-page">
        <div className="content_login-form content-form">
          <span className="content-form-title">Start Your Day with Digital Brain</span>
          {!!loginError && <div className="login-error">{loginError}</div>}
          <form name="LoginForm" onSubmit={logIn}>
            <div className="form-inputs">
              <Input
                label="Email"
                className="form-input"
                value={email}
                error={!!emailError}
                errorText={emailError}
                onChange={e => setEmail(e.target.value)}
                placeholder="email"
                required
              />
              <Input
                label="Password"
                className="form-input"
                type="password"
                value={password}
                error={!!passwordError}
                errorText={passwordError}
                onChange={e => setPassword(e.target.value)}
                placeholder="password"
                required
              />
            </div>
            <Button
              className="login-submit-button"
              type="primary"
              htmlType="submit"
              loading={isProcessing}
              onClick={logIn}
            >
              Log In
            </Button>
          </form>
          <div className="footer">
            <Button
              className="registration-button"
              loading={isProcessing}
              onClick={() => navigate(PATHS.signUp.url)}
            >
              Create New Account
            </Button>
          </div>
        </div>
      </div>
    </AuthWrapper>
  )
}
