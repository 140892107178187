import { Select as AntdSelect, SelectProps } from 'antd/es'
import classNames from 'classnames'
import './select.scss'

export const Select = ({ className, ...restProps }: SelectProps) => (
  <AntdSelect
    className={classNames('dibn-select', className)}
    popupClassName="dibn-select-dropdown"
    {...restProps}
  />
)

export const Option = AntdSelect.Option
