import { useCallback, useMemo, useState, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { Collapse } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { parseCamelCase } from 'helpers/helpers'
import { Button, Loader } from 'components/common'
import { objectsPageSelectors } from '../../object.selectors'
import { TObjectForm } from '../../hooks/useObjectForm'
import { ERelationTypes, TRelations, IObject, IRelation } from '../../types/object.types'
import { AddRelationsModal } from './addRelationsModal/addRelationsModal'
import './relations.scss'

const { Panel } = Collapse

type TRelationsProps = {
  objectId?: IObject['id']
  relations: TRelations
  reloadRelations?: TObjectForm['reloadRelations']
  openNewObject: TObjectForm['openNewObject']
  updateRelations: (relations: TRelations) => void
  onCopy: TObjectForm['onCopy']
}

type TRelationsSectionProps = {
  type: ERelationTypes
  relations: TRelationsProps['relations']
  objectCount: number
  isReadOnlyMode: boolean
  openNewObject: TObjectForm['openNewObject']
  updateRelations: TRelationsProps['updateRelations']
  onCopy: TObjectForm['onCopy']
}

type TAddModalState = {
  isOpen: boolean
  relationProperty?: IRelation['Property'] | undefined
}

type TToggleAddModal = (relationProperty?: TAddModalState['relationProperty']) => void

const Change = ({
  isReadOnlyMode,
  onClick
}: {
  isReadOnlyMode: boolean
  onClick: (e: MouseEvent<HTMLElement>) => void
}) => (
  <Button className="extra-button" disabled={isReadOnlyMode} onClick={onClick}>
    Add/Remove
  </Button>
)

const RelationsSection = ({
  type,
  relations,
  objectCount,
  isReadOnlyMode,
  openNewObject,
  updateRelations,
  onCopy
}: TRelationsSectionProps) => {
  const [addModalState, setAddModalState] = useState<TAddModalState>()

  const toggleAddModal: TToggleAddModal = useCallback(relationProperty => {
    setAddModalState(_addModalState => {
      if (_addModalState?.isOpen) {
        return { ..._addModalState, isOpen: false }
      } else {
        return { isOpen: true, relationProperty }
      }
    })
  }, [])

  const relationPanels = useMemo(() => {
    return relations[type]?.map(relation => {
      const _toggleAddModal = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()

        toggleAddModal(relation.Property)
      }

      const objectTypes = relation.ObjectTypes.join(', ')

      const headerText =
        type === ERelationTypes.Outbound
          ? `THIS ${parseCamelCase(relation.Property)} [${parseCamelCase(objectTypes)}]`
          : `[${parseCamelCase(objectTypes)}] ${parseCamelCase(relation.Property)} THIS`

      const canOpenNewTab = objectCount <= 1

      return (
        <Panel
          key={relation.Property}
          header={
            <span className="relation-header-text" title={headerText}>
              {headerText}
            </span>
          }
          className="relation-body__item"
          extra={<Change isReadOnlyMode={isReadOnlyMode} onClick={_toggleAddModal} />}
        >
          {relation.Objects.map(object => (
            <div className="relation-body__item--content" key={object.id}>
              <div className="data">
                <div className="short" title={object.ShortDescription}>
                  {object.ShortDescription}
                </div>
                <div className="long" title={object.LongDescription}>
                  {object.LongDescription}
                </div>
              </div>
              <div className="actions">
                <Button
                  className="action"
                  disabled={!canOpenNewTab}
                  onClick={() => openNewObject(object.id)}
                >
                  View / Edit
                </Button>
                <Button
                  className="action"
                  disabled={isReadOnlyMode || !canOpenNewTab}
                  onClick={() => onCopy(object.id)}
                >
                  Copy
                </Button>
              </div>
            </div>
          ))}
        </Panel>
      )
    })
  }, [relations, type, objectCount, isReadOnlyMode, openNewObject, onCopy, toggleAddModal])

  const relation = useMemo(() => {
    if (!addModalState?.relationProperty) return null

    return relations[type]?.find(_relation => _relation.Property === addModalState.relationProperty)
  }, [addModalState?.relationProperty, relations, type])

  const descriptionText =
    type === ERelationTypes.Outbound
      ? 'The current card (THIS) determines the relation to other cards in the list'
      : 'The relation to the current card (THIS) is determined by other cards in the list'

  return (
    <div className="relations-section">
      <div className="relation-title">
        <span className="title">{`${type} relation`}</span>
        <span className="info">{descriptionText}</span>
      </div>
      <Collapse ghost={true} bordered={false} className="relation-body">
        {relationPanels}
      </Collapse>
      <AddRelationsModal
        type={type}
        relation={relation}
        relations={relations}
        isOpen={addModalState?.isOpen}
        updateRelations={updateRelations}
        close={() => toggleAddModal()}
      />
    </div>
  )
}

export const Relations = ({
  objectId,
  relations,
  reloadRelations,
  openNewObject,
  updateRelations,
  onCopy
}: TRelationsProps): JSX.Element | null => {
  const [areRelationsLoading, setAreRelationsLoading] = useState<boolean>(false)

  const objects = useSelector(objectsPageSelectors.getObjects)
  const isReadOnlyMode = useSelector(objectsPageSelectors.getIsReadOnlyMode)
  const isLightReadOnlyMode = useSelector(objectsPageSelectors.getIsLightReadOnlyMode)

  if (!relations) return null

  const objectCount = objects.length
  const isReloadAvailable = !!reloadRelations && !!objectId

  const _reloadRelations = () => {
    if (!isReloadAvailable) return

    setAreRelationsLoading(true)

    reloadRelations(objectId).finally(() => setAreRelationsLoading(false))
  }

  const areRelationsInReadOnlyMode = isReadOnlyMode || isLightReadOnlyMode

  return (
    <Loader spinning={areRelationsLoading}>
      <div className="relations">
        {isReloadAvailable && (
          <Button className="refresh" icon={<ReloadOutlined />} onClick={_reloadRelations}>
            Refresh
          </Button>
        )}
        {relations.Outbound && (
          <RelationsSection
            type={ERelationTypes.Outbound}
            relations={relations}
            objectCount={objectCount}
            isReadOnlyMode={areRelationsInReadOnlyMode}
            openNewObject={openNewObject}
            updateRelations={updateRelations}
            onCopy={onCopy}
          />
        )}
        {relations.Inbound && (
          <RelationsSection
            type={ERelationTypes.Inbound}
            relations={relations}
            objectCount={objectCount}
            isReadOnlyMode={areRelationsInReadOnlyMode}
            openNewObject={openNewObject}
            updateRelations={updateRelations}
            onCopy={onCopy}
          />
        )}
      </div>
    </Loader>
  )
}

export default Relations
