import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import { Button, message } from 'components/common'
import { TSubObject, IObject } from '../../types/object.types'
import { objectPageSlice } from '../../object.slice'
import { AttachmentItem } from './attachmentItem'
import { nanoid } from 'nanoid'

type AttachmentsProps = {
  object: IObject
}

export const MAX_ATTACHMENTS = 10

export const Attachments = ({ object }: AttachmentsProps): JSX.Element => {
  const dispatch = useDispatch()

  const attachments = useMemo(
    () => object.SubObjects?.filter(attachment => attachment.Category === 'LinkSubobject'),
    [object.SubObjects]
  )

  const changeAttachment = (attachment: TSubObject) => {
    const updatedAttachments = object.SubObjects?.reduce(
      (result: Array<TSubObject>, _attachment: TSubObject) => {
        if (_attachment.id === attachment.id) result.push(attachment)
        else result.push(_attachment)

        return result
      },
      []
    )

    dispatch(
      objectPageSlice.actions.updateObject({
        id: object.id,
        changes: { SubObjects: updatedAttachments }
      })
    )
  }

  const createAttachment = () => {
    const attachmentTemplate = object.SubObjectTemplates?.find(
      subObject => subObject.Category === 'LinkSubobject'
    )

    if (!attachmentTemplate) {
      message.error({
        content: 'An error occurred while creating attachment. Try to reload the page.'
      })

      throw new Error()
    } else {
      const newSubObject = { ...attachmentTemplate, id: nanoid() }

      dispatch(
        objectPageSlice.actions.updateObject({
          id: object.id,
          changes: { SubObjects: [...(object.SubObjects || []), newSubObject] }
        })
      )
    }
  }

  const removeAttachment = (id: TSubObject['id']) => {
    const updatedAttachments = object.SubObjects?.filter(subObject => subObject.id !== id)

    dispatch(
      objectPageSlice.actions.updateObject({
        id: object.id,
        changes: { SubObjects: updatedAttachments }
      })
    )
  }

  return (
    <div className="attachments">
      <div className="attachment">
        <div className="label">Link Documents and Systems</div>
        <Button
          icon={<PlusOutlined />}
          disabled={attachments?.length === MAX_ATTACHMENTS}
          onClick={createAttachment}
        >
          Add link
        </Button>
      </div>
      <div className="attachment-items">
        {!!attachments?.length ? (
          attachments.map(attachment => (
            <AttachmentItem
              key={attachment.id}
              attachment={attachment}
              change={changeAttachment}
              remove={removeAttachment}
            />
          ))
        ) : (
          <span className="empty-attachments">There are no attachments here yet</span>
        )}
      </div>
    </div>
  )
}
