import { ReactNode, useMemo, SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import { nanoid } from 'nanoid'
import { Collapse } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { parseCamelCase } from 'helpers/helpers'
import { Button, message } from 'components/common'
import { IObject, TSubObject } from '../../types/object.types'
import { TObjectForm } from '../../hooks/useObjectForm'
import { objectPageSlice } from '../../object.slice'
import { SubObjectFields } from './subObjectFields/subObjectFields'
import Relations from '../relations/relations'
import './subObjects.scss'

const { Panel } = Collapse

type TProps = {
  object: IObject
  openNewObject: TObjectForm['openNewObject']
  onCopy: TObjectForm['onCopy']
}

type TCategorizedSubObjects = Array<{
  category: string
  subObjects: TSubObject[]
  isMultiple: boolean
}>

const SubObjectHeader = ({
  name,
  remove
}: {
  name: ReactNode
  remove: (e: SyntheticEvent) => void
}) => (
  <>
    {name ? <span>{name}</span> : <span className="no-sub-object-name">No Short Description</span>}
    <Button className="remove-sub-object" size="small" danger onClick={remove}>
      Remove
    </Button>
  </>
)

export const SubObjects = ({ object, openNewObject, onCopy }: TProps) => {
  const dispatch = useDispatch()

  const categorizedSubObjects = useMemo(() => {
    return object.SubObjectTemplates?.reduce((result, subObjectTemplate) => {
      if (subObjectTemplate.Category === 'LinkSubobject') return result

      const categorySubObjects =
        object.SubObjects?.filter(subObject => subObject.Category === subObjectTemplate.Category) ||
        []

      const category = {
        category: subObjectTemplate.Category,
        subObjects: categorySubObjects,
        isMultiple: subObjectTemplate.Multiple || false
      }

      result.push(category)

      return result
    }, [] as TCategorizedSubObjects)
  }, [object.SubObjects, object.SubObjectTemplates])

  const updateSubObjectRelations = (
    id: TSubObject['id'],
    newRelations: TSubObject['Relations']
  ) => {
    const newSubObjects = object.SubObjects?.map(_subObject => {
      if (_subObject.id !== id) return _subObject

      return { ..._subObject, Relations: newRelations }
    })

    dispatch(
      objectPageSlice.actions.updateObject({
        id: object.id,
        changes: { SubObjects: newSubObjects }
      })
    )
  }

  const createSubObject = (category: TSubObject['Category']) => {
    const subObjectTemplate = object.SubObjectTemplates?.find(
      subObject => subObject.Category === category
    )

    if (!subObjectTemplate) {
      message.error({
        content: 'An error occurred while creating sub-object. Try to reload the page.'
      })

      throw new Error()
    } else {
      const newSubObject = { ...subObjectTemplate, id: nanoid() }

      dispatch(
        objectPageSlice.actions.updateObject({
          id: object.id,
          changes: { SubObjects: [...(object.SubObjects || []), newSubObject] }
        })
      )
    }
  }

  const removeSubObject = (subObject: TSubObject) => {
    if (!object.SubObjects) return

    const newSubObjects = object.SubObjects.filter(_subObject => _subObject.id !== subObject.id)

    dispatch(
      objectPageSlice.actions.updateObject({
        id: object.id,
        changes: { SubObjects: newSubObjects }
      })
    )
  }

  if (!categorizedSubObjects) return null

  return (
    <ul className="sub-objects">
      {categorizedSubObjects.map(subObjectCategory => {
        const canAddSubObject = subObjectCategory.isMultiple || !subObjectCategory.subObjects.length

        return (
          <li key={subObjectCategory.category} className="sub-object-category">
            {parseCamelCase(subObjectCategory.category)}
            <Button
              className="add-sub-object"
              icon={<PlusOutlined />}
              title={!canAddSubObject ? "Can't add more sub-objects" : undefined}
              disabled={!canAddSubObject}
              onClick={() => createSubObject(subObjectCategory.category)}
            >
              Add
            </Button>
            <Collapse className="sub-object-category" accordion bordered={false}>
              {subObjectCategory.subObjects.map(subObject => (
                <Panel
                  key={subObject.id}
                  className="sub-object-panel"
                  header={
                    <SubObjectHeader
                      name={subObject.Fields.ShortDescription.value}
                      remove={e => {
                        e.preventDefault()

                        removeSubObject(subObject)
                      }}
                    />
                  }
                >
                  <SubObjectFields object={object} subObject={subObject} />
                  {!!subObject.Relations && (
                    <Relations
                      relations={subObject.Relations}
                      updateRelations={newRelations =>
                        updateSubObjectRelations(subObject.id, newRelations)
                      }
                      openNewObject={openNewObject}
                      onCopy={onCopy}
                    />
                  )}
                </Panel>
              ))}
            </Collapse>
          </li>
        )
      })}
    </ul>
  )
}
