import { ReactElement } from 'react'
import classNames from 'classnames'
import { ModalProps } from 'antd/lib/modal'
import { Modal as AntModal } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal/Modal'

export interface IModalProps extends ModalProps {
  children: ReactElement
}

class _Modal {
  DEFAULT_PARAMS = {
    className: 'dibn-modal',
    okButtonProps: {
      className: 'dibn-button'
    },
    cancelButtonProps: {
      className: 'dibn-button'
    },
    destroyOnClose: true
  } as IModalProps

  open = (config: ModalFuncProps) => {
    const _config = { ...this.DEFAULT_PARAMS, ...config } as ModalFuncProps

    return AntModal.confirm(_config)
  }

  confirm = (config: ModalFuncProps) => this.open({ ...config, type: 'success' })
  success = (config: ModalFuncProps) => this.open({ ...config, type: 'success' })
  error = (config: ModalFuncProps) => this.open({ ...config, type: 'error' })
  info = (config: ModalFuncProps) => this.open({ ...config, type: 'info' })
  warning = (config: ModalFuncProps) => this.open({ ...config, type: 'warning' })
}

export const modal = new _Modal()

export const Modal = ({ className, ...props }: IModalProps) => (
  <AntModal
    {...modal.DEFAULT_PARAMS}
    className={classNames(modal.DEFAULT_PARAMS.className, className)}
    {...props}
  >
    {props.children}
  </AntModal>
)
