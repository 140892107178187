import { ReactElement } from 'react'
import classNames from 'classnames'
import { Spin, SpinProps } from 'antd'
import './loader.scss'

export interface ILoader extends SpinProps {
  children?: ReactElement |  ReactElement[]
}

export const Loader = (props: ILoader) => (
  <Spin {...props} className={classNames('dibn-loader', props.className)} />
)
