import { ReactNode, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useValueRef } from 'hooks/useValueRef'
import { IObject, TSubObject } from '../../../types/object.types'
import { objectPageSlice } from '../../../object.slice'
import { ObjectField } from '../../objectFields/field/objectField'
import './subObjectFields.scss'

type TProps = {
  object: IObject
  subObject: TSubObject
}

export const SubObjectFields = ({ object, subObject }: TProps) => {
  const dispatch = useDispatch()

  const objectRef = useValueRef(object)

  const changeField = useCallback(
    (key: string, value: string) => {
      const newSubObjects = objectRef.current.SubObjects?.map(_subObject => {
        if (_subObject.id !== subObject.id) return _subObject

        return {
          ..._subObject,
          Fields: { ..._subObject.Fields, [key]: { ..._subObject.Fields[key], value } }
        }
      })

      dispatch(
        objectPageSlice.actions.updateObject({
          id: objectRef.current.id,
          changes: { SubObjects: newSubObjects }
        })
      )
    },
    [subObject.id, objectRef, dispatch]
  )

  const getFields = () => {
    if (!subObject) return null

    return window.Object.values(subObject.Fields).reduce((result: Array<ReactNode>, field) => {
      // Don't render hidden fields.
      if (field.hidden) return result

      const Component = (
        <ObjectField key={field.property_name} field={field} changeField={changeField} />
      )

      if (!Component) return result

      result.push(Component)

      return result
    }, [])
  }

  return <div className="sub-object-fields">{getFields()}</div>
}
