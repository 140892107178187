import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { PATHS } from 'constants/routes'
import { emailRegexp } from 'constants/validationRegexps'
import { commonSlice } from 'store/commonSlice'
import { HttpService } from 'services/http.service'

const DEFAULT_LOGIN_ERROR_MESSAGE = 'Unknown error occurred while logging in. Please try again.'

type UseLoginForm = {
  email: string
  password: string
  emailError: string
  passwordError: string
  loginError: string
  isProcessing: boolean
  setEmail: (value: string) => void
  setPassword: (value: string) => void
  logIn: (e: FormEvent) => void
}

export const useLoginForm = (): UseLoginForm => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [loginError, setLoginError] = useState<string>('')
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Prefill email if it's in the state.
  useEffect(() => {
    const state = window.history.state as { email: string } | null

    if (state?.email) {
      setEmail(state.email)
    }
  }, [])

  const validateForm = useCallback((email: string, password: string): boolean => {
    setLoginError('')
    setEmailError('')
    setPasswordError('')

    let isFormValid = true

    if (!email || !emailRegexp.test(email)) {
      setEmailError('Please enter valid email')
      isFormValid = false
    }

    if (!password) {
      setPasswordError('Please enter valid password')
      isFormValid = false
    }

    return isFormValid
  }, [])

  const logIn = useCallback(
    (e: FormEvent): void => {
      e.preventDefault()

      const isFormValid = validateForm(email, password)

      if (isFormValid) {
        setIsProcessing(true)

        HttpService.post('/auth/token/', { email, password })
          .then(() => {
            // Reset common slice to clear possibly obsolete state.
            dispatch(commonSlice.actions.resetCommonSlice())

            navigate(PATHS.launchpad.url)
          })
          .catch((error: AxiosError<{ non_field_errors?: string[] }>) => {
            setLoginError(
              error.response?.data?.non_field_errors?.[0] || DEFAULT_LOGIN_ERROR_MESSAGE
            )
          })
          .finally(() => {
            setIsProcessing(false)
          })
      }
    },
    [email, password, validateForm, navigate, dispatch]
  )

  return {
    email,
    password,
    emailError,
    passwordError,
    loginError,
    isProcessing,
    setEmail,
    setPassword,
    logIn
  }
}
