/**
 * Add space between camelCase text.
 */
export const parseCamelCase = (str: string | null | undefined): string => {
  if (!str) return ''

  str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')

  return str
}
