import { RootState } from 'store/store'
import { IObjectPageSlice, objectsAdapter } from './object.slice'

const objectsSelectors = objectsAdapter.getSelectors((state: IObjectPageSlice) => state.objects)

const getObjectPageSlice = (state: RootState) => state.objectPage
const getIsReadOnlyMode = (state: RootState) => state.objectPage.isReadOnlyMode
const getIsLightReadOnlyMode = (state: RootState) => state.objectPage.isLightReadOnlyMode
const getObjects = (state: RootState) => objectsSelectors.selectAll(state.objectPage)

const getActiveObject = (state: RootState) => {
  const { activeObjectId } = getObjectPageSlice(state)

  return objectsSelectors.selectById(state.objectPage, activeObjectId)
}

export const objectsPageSelectors = {
  getObjectPageSlice,
  getObjects,
  getActiveObject,
  getIsReadOnlyMode,
  getIsLightReadOnlyMode
}
