import { useSelector } from 'react-redux'
import { Button } from 'components/common'
import { objectsPageSelectors } from '../../object.selectors'
import { TObjectForm } from '../../hooks/useObjectForm'
import './footer.scss'

type ObjectFooterProps = {
  onSave: () => void
  onCopy: TObjectForm['onCopy']
  onDelete: () => void
}

export const ObjectFooter = ({ onSave, onCopy, onDelete }: ObjectFooterProps): JSX.Element => {
  const objects = useSelector(objectsPageSelectors.getObjects)
  const { activeObjectId } = useSelector(objectsPageSelectors.getObjectPageSlice)
  const isReadOnlyMode = useSelector(objectsPageSelectors.getIsReadOnlyMode)
  const isLightReadOnlyMode = useSelector(objectsPageSelectors.getIsLightReadOnlyMode)

  const isCopyDisabled = isReadOnlyMode || isLightReadOnlyMode || objects.length > 1

  return (
    <div className="object-footer">
      <Button
        className="object-footer-button"
        type="primary"
        disabled={isReadOnlyMode}
        onClick={onSave}
      >
        Save
      </Button>
      <Button
        className="object-footer-button"
        disabled={isCopyDisabled}
        type="primary"
        onClick={() => onCopy(activeObjectId)}
      >
        Copy
      </Button>
      <Button
        className="object-footer-button"
        type="primary"
        disabled={isReadOnlyMode || isLightReadOnlyMode}
        onClick={onDelete}
      >
        Delete
      </Button>
    </div>
  )
}
