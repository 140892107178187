import { ReactElement } from 'react'
import logo from 'assets/img/logo.svg'
import ThinkingImg from 'assets/img/thinking.png'
import './authWrapper.scss'

const RequestTrialButton = (): JSX.Element => (
  <a href="https://rock15.com/request-digital-brain/" className="request-trial-button">
    <div className="title">
      <span>One Stop Help Desk</span>
      <span className="subtitle">"Need a system, need help?"</span>
    </div>
    <img src={ThinkingImg} alt="Request Trial Landscape" />
  </a>
)

export const AuthWrapper = ({
  children
}: {
  children: ReactElement | ReactElement[]
}): JSX.Element => (
  <div className="auth-pages-wrapper">
    <div className="header">
      <img src={logo} alt="" />
    </div>
    <div className="information">
      <div className="title">INTELLIGENT KNOWLEDGE COMMUNICATION PLATFORM</div>
      <div className="opportunities">
        <div className="opportunity">Make Transformation Story Explicit</div>
        <div className="opportunity">
          Drive Alignment Across Organizations, Partners, and Customers
        </div>
      </div>
    </div>
    <div className="page-content">
      <div className="form-wrapper">{children}</div>
      <RequestTrialButton />
    </div>
  </div>
)
