import { DeleteOutlined } from '@ant-design/icons'
import { Input } from 'components/common'
import { TSubObject } from '../../types/object.types'

export type AttachmentItemProps = {
  attachment: TSubObject
  change: (attachment: TSubObject) => void
  remove: (id: TSubObject['id']) => void
}

export const AttachmentItem = ({
  attachment,
  change,
  remove
}: AttachmentItemProps): JSX.Element => {
  const onChange = (key: string, value: string): void => {
    change({
      ...attachment,
      Fields: {
        ...attachment.Fields,
        [key]: {
          ...attachment.Fields[key],
          value
        }
      }
    })
  }

  return (
    <div className="attachment-item">
      <div className="description">
        <Input
          placeholder="Short Description (Text)"
          onChange={e => onChange('ShortDescription', e.target.value)}
          value={attachment.Fields.ShortDescription.value}
        />
        <DeleteOutlined onClick={() => remove(attachment.id)} />
      </div>
      <div className="link">
        <Input
          placeholder="Link"
          onChange={e => onChange('HasLinkUrl', e.target.value)}
          value={attachment.Fields.HasLinkUrl.value}
        />
      </div>
    </div>
  )
}
