export const StarPlus = () => (
  <div className="icon-component">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.1079 10.0143C21.4415 9.72473 21.2632 9.17647 20.8231 9.13851L14.9299 8.63034C14.8561 8.62398 14.7919 8.57738 14.763 8.50922L12.4606 3.08512C12.2881 2.67881 11.7122 2.67897 11.54 3.08537L9.23706 8.51896C9.20811 8.58726 9.14374 8.63392 9.06982 8.6402L3.18081 9.13982C2.7401 9.17721 2.56139 9.72634 2.89569 10.0159L7.36876 13.891C7.42487 13.9396 7.44944 14.0153 7.43257 14.0876L6.0892 19.846C5.98872 20.2767 6.45584 20.6162 6.8345 20.3877L11.8966 17.3324C11.9602 17.294 12.0398 17.294 12.1033 17.3324L17.1674 20.3888C17.5458 20.6172 18.0127 20.2784 17.9128 19.8478L16.5772 14.0873C16.5605 14.0151 16.585 13.9396 16.6409 13.8911L21.1079 10.0143ZM12.1034 15.4625C12.0398 15.4241 11.9602 15.4241 11.8965 15.4625L8.64603 17.4249C8.49454 17.5163 8.30764 17.3805 8.3479 17.2081L9.21254 13.5075C9.22943 13.4352 9.2049 13.3596 9.14884 13.3109L6.27679 10.8195C6.14333 10.7037 6.21454 10.4844 6.39055 10.4692L10.1799 10.1404C10.2538 10.134 10.318 10.0872 10.3469 10.0189L11.816 6.5361C11.8848 6.37319 12.1156 6.37305 12.1845 6.53588L13.6631 10.0291C13.6919 10.0973 13.7562 10.144 13.83 10.1504L17.6194 10.4792C17.7954 10.4944 17.8667 10.7137 17.7332 10.8295L14.8612 13.3209C14.8051 13.3696 14.7806 13.4452 14.7975 13.5175L15.662 17.2177C15.7022 17.39 15.5152 17.5259 15.3637 17.4343L12.1034 15.4625Z"
        fill="#424242"
      />
      <path
        d="M19.75 6.05V7.7C19.75 7.86569 19.6157 8 19.45 8H18.55C18.3843 8 18.25 7.86569 18.25 7.7V6.05C18.25 5.88432 18.1157 5.75 17.95 5.75H16.3C16.1343 5.75 16 5.61569 16 5.45V4.55C16 4.38431 16.1343 4.25 16.3 4.25H17.95C18.1157 4.25 18.25 4.11569 18.25 3.95V2.3C18.25 2.13431 18.3843 2 18.55 2H19.45C19.6157 2 19.75 2.13431 19.75 2.3V3.95C19.75 4.11569 19.8843 4.25 20.05 4.25H21.7C21.8657 4.25 22 4.38431 22 4.55V5.45C22 5.61569 21.8657 5.75 21.7 5.75H20.05C19.8843 5.75 19.75 5.88432 19.75 6.05Z"
        fill="#424242"
      />
    </svg>
  </div>
)
