import { ReactNode, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useValueRef } from 'hooks/useValueRef'
import { IObject, TObjectField } from '../../types/object.types'
import { objectPageSlice } from '../../object.slice'
import { ObjectField } from './field/objectField'
import './objectFields.scss'

type TProps = {
  object: IObject
}

export const ObjectFields = ({ object }: TProps) => {
  const dispatch = useDispatch()

  const objectRef = useValueRef(object)

  const changeField = useCallback(
    (key: string, value: string) => {
      dispatch(
        objectPageSlice.actions.updateObject({
          id: objectRef.current.id,
          changes: {
            Fields: {
              ...objectRef.current.Fields,
              [key]: {
                ...objectRef.current.Fields[key],
                value
              } as TObjectField
            }
          }
        })
      )
    },
    [objectRef, dispatch]
  )

  const getFields = () => {
    if (!object) return null

    return window.Object.values(object.Fields).reduce((result: Array<ReactNode>, field) => {
      // Don't render hidden fields.
      if (field.hidden) return result

      const Component = (
        <ObjectField key={field.property_name} field={field} changeField={changeField} />
      )

      if (!Component) return result

      result.push(Component)

      return result
    }, [])
  }

  return <div className="object-fields">{getFields()}</div>
}
