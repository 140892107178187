enum EPages {
  login = 'login',
  signUp = 'signUp',
  launchpad = 'launchpad',
  object = 'object'
}

type TPath = {
  id: EPages
  name: string
  url: string
  routerPath: string
  isAuthPage?: boolean
  requiresAuth?: boolean
}

export const PATHS: Record<EPages, TPath> = {
  [EPages.login]: {
    id: EPages.login,
    name: 'Login',
    url: '/login',
    routerPath: '/login',
    isAuthPage: true
  },
  [EPages.signUp]: {
    id: EPages.signUp,
    name: 'Sign Up',
    url: '/signup',
    routerPath: '/signup',
    isAuthPage: true
  },
  [EPages.launchpad]: {
    id: EPages.launchpad,
    name: 'Launchpad',
    url: '/launchpad',
    routerPath: '/launchpad',
    requiresAuth: true
  },
  [EPages.object]: {
    id: EPages.object,
    name: 'Object',
    url: '/object',
    routerPath: '/object/:id',
    requiresAuth: true
  }
} as const

export const PATHS_LIST = Object.values(PATHS)

export const PROTECTED_ROUTES_LIST = PATHS_LIST.filter(path => path.requiresAuth)
