import { useSelector } from 'react-redux'
import { Empty } from 'antd'
import { Loader } from 'components/common'
import { objectsPageSelectors } from '../object.selectors'

type ObjectWrapperProps = {
  children: JSX.Element[]
  isLoading: boolean
}

export const ObjectWrapper = ({ children, isLoading }: ObjectWrapperProps) => {
  const objects = useSelector(objectsPageSelectors.getObjects)

  if (!objects.length && !isLoading) return <Empty />

  return (
    <main className="object">
      {isLoading ? (
        <div className="loader-wrapper">
          <Loader size="large" />
        </div>
      ) : (
        children
      )}
    </main>
  )
}
