import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import classNames from 'classnames'
import closeIcon from 'assets/img/close.svg'
import { parseCamelCase } from 'helpers/helpers'
import { objectPageSlice } from '../../object.slice'
import { objectsPageSelectors } from '../../object.selectors'
import { IObject } from '../../types/object.types'
import './header.scss'

type ObjectHeaderProps = {
  closeTab: (id: IObject['id']) => void
}

export const ObjectHeader = ({ closeTab }: ObjectHeaderProps): JSX.Element => {
  const { activeObjectId } = useSelector(objectsPageSelectors.getObjectPageSlice)
  const objects = useSelector(objectsPageSelectors.getObjects)
  const dispatch = useDispatch()

  const changeActiveObjectId = (id: EntityId) => {
    dispatch(objectPageSlice.actions.setActiveObjectId(id))
  }

  const close = (id: IObject['id'], e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    closeTab(id)
  }

  return (
    <div className="object-header">
      {objects.map(object => (
        <div
          key={object.id}
          className={classNames('object-tab', object.id === activeObjectId && 'active-tab')}
          onClick={() => changeActiveObjectId(object.id)}
        >
          <span className="tab-title">{object.Fields.ShortDescription.value}</span>
          <span className="tab-text">
            {object.Fields.Rock15Id.value}, {object.id}
          </span>
          <span className="tab-text">{parseCamelCase(object.Category)}</span>
          {objects.length > 1 && (
            <img
              className="tab-close"
              src={closeIcon}
              alt="Close"
              onClick={e => close(object.id, e)}
            />
          )}
        </div>
      ))}
    </div>
  )
}
