import { ReactNode } from 'react'
import { message as AntMessage } from 'antd'
import { ArgsProps } from 'antd/lib/message'
import './message.scss'

interface MessageConfig extends Omit<ArgsProps, 'duration' | 'type'> {
  content: ReactNode
  duration?: number | null
  type?: 'info' | 'success' | 'error' | 'warning' | 'loading'
}

class Message {
  DEFAULT_PARAMS = {
    className: 'dibn-message',
    duration: 3
  } as ArgsProps

  message = (config: MessageConfig) => {
    const _config = { ...this.DEFAULT_PARAMS, ...config } as ArgsProps

    return AntMessage.open(_config)
  }

  success = (config: MessageConfig) => this.message({ ...config, type: 'success' })
  error = (config: MessageConfig) => this.message({ duration: 5, ...config, type: 'error' })
  info = (config: MessageConfig) => this.message({ ...config, type: 'info' })
  warning = (config: MessageConfig) => this.message({ ...config, type: 'warning' })
  loading = (config: MessageConfig) => this.message({ ...config, type: 'loading' })
}

export const message = new Message()
