import { ReactNode } from 'react'
import './label.scss'

type TProps = {
  requireInput?: boolean
  children: ReactNode
}

export const Label = ({ requireInput, children }: TProps) => (
  <label className="dibn-label">
    {children}
    {!!requireInput && <span>*</span>}
  </label>
)
