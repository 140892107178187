import { ReactElement, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { WarningOutlined } from '@ant-design/icons'
import { getUser } from 'store/commonSlice'
import { HttpService } from 'services/http.service'
import { Loader, message } from 'components/common'
import { ItemDrag } from 'pages/launchPad/components/itemDrag/itemDrag'
import './workspaceTiles.scss'

type WorkspaceTile = {
  uuid: string
  first_title: string
  second_title: string
  model_title: string
  company_name: string
  workspace: string
  image: string
  is_favorite: boolean
  graph_url: string
  dcc_application: string | null
}

const Tile = ({ tile }: { tile: WorkspaceTile }) => {
  const searchParams = new URLSearchParams({ 'graph-dataModel': tile.model_title })

  if (tile.dcc_application) {
    searchParams.append('dcc_app_id', tile.dcc_application)
    searchParams.append('templates-dcc_app_id', tile.dcc_application)
    searchParams.append('tools-dcc_app_id', tile.dcc_application)
    searchParams.append('config-dcc_app_id', tile.dcc_application)
    searchParams.append('dictionary-dcc_app_id', tile.dcc_application)
    searchParams.append('configUrl', `/api/config/?dcc_app_id=${tile.dcc_application}`)
  }

  const redirectURL = `/dcc?${searchParams.toString()}`

  return (
    <ItemDrag
      key={tile.uuid}
      title={tile.first_title}
      name={tile.second_title}
      img={tile.image}
      redirect={redirectURL}
    />
  )
}

const TilesHeader = ({ title, label }: { title: string; label: string }) => (
  <div className="tiles-header">
    <div className="title">{title}</div>
    <div className="label">{label}</div>
  </div>
)

export const WorkspaceTiles = (): JSX.Element => {
  const [workspaceTiles, setWorkspaceTiles] = useState<WorkspaceTile[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  const user = useSelector(getUser)

  useLayoutEffect(() => {
    if (!user || user.isLoading) return
    if (user.isError) {
      setIsError(true)
      return
    }

    setIsLoading(true)
    setIsError(false)

    HttpService.get(`workspaces/${user.activeWorkspace}/`)
      .then(res => {
        setWorkspaceTiles(res.data?.tiles)
      })
      .catch(e => {
        setIsError(true)

        if (!e.isHandled) {
          message.error({
            content: 'An error occurred while fetching workspace tiles. Try to reload the page.'
          })
        }
      })
      .finally(() => setIsLoading(false))
  }, [user])

  const favoriteTiles = useMemo(() => {
    if (isLoading) return <Loader size="large" />
    if (isError) return <WarningOutlined className="error-icon" />

    return workspaceTiles?.reduce((result: Array<ReactElement>, tile) => {
      if (!tile.is_favorite) return result

      result.push(<Tile key={tile.uuid} tile={tile} />)

      return result
    }, [])
  }, [workspaceTiles, isLoading, isError])

  const allTiles = useMemo(() => {
    if (isLoading) return <Loader size="large" />
    if (isError) return <WarningOutlined className="error-icon" />

    return workspaceTiles?.reduce((result: Array<ReactElement>, tile) => {
      if (tile.is_favorite) return result

      result.push(<Tile key={tile.uuid} tile={tile} />)

      return result
    }, [])
  }, [workspaceTiles, isLoading, isError])

  return (
    <main className="workspace-tiles">
      <header>
        <TilesHeader title="My Home" label="Your favorite Digital Twin Models" />
        <TilesHeader
          title="Rock 15 model company for innovation"
          label="Available reference models (templates) that includes industry best practices and business examples"
        />
      </header>
      <div className="tiles-container">
        <div className="favorite-tiles">
          <div className="tiles-list">{favoriteTiles}</div>
        </div>
        <div className="all-tiles">
          <div className="tiles-list">{allTiles}</div>
        </div>
      </div>
    </main>
  )
}
