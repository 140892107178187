import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import { message, modal } from 'components/common'
import { HttpService } from 'services/http.service'
import { IObject, TRelations } from '../types/object.types'
import { objectPageSlice } from '../object.slice'
import { objectsPageSelectors } from '../object.selectors'

export type TObjectForm = {
  isLoading: boolean
  onCopy: (id: IObject['id']) => Promise<IObject['Relations'] | void>
  onSave: () => void
  onDelete: () => void
  reloadRelations: (id: IObject['id']) => Promise<IObject['Relations'] | void>
  updateObjectRelations: (newRelations: TRelations) => void
  openNewObject: (id: IObject['id']) => Promise<void>
  closeTab: (id: IObject['id']) => void
}

// TODO: Move functions to Redux actions.
export const useObjectForm = (id: IObject['id']): TObjectForm => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { search } = useLocation()

  const objects = useSelector(objectsPageSelectors.getObjects)
  const { activeObjectId } = useSelector(objectsPageSelectors.getObjectPageSlice)
  const dispatch = useDispatch()

  const objectsRef = useRef(objects)

  useEffect(() => {
    const queryParams = new URLSearchParams(search)

    const isReadOnly = !!queryParams.get('is-read-only')
    const isLightReadOnly = !!queryParams.get('is-light-read-only')

    dispatch(objectPageSlice.actions.setIsReadOnlyMode(isReadOnly))
    dispatch(objectPageSlice.actions.setIsLightReadOnlyMode(isLightReadOnly))

    fetchObject(id, { isInitialFetching: true }).then(object => {
      if (object) dispatch(objectPageSlice.actions.setActiveObjectId(object.id))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    objectsRef.current = objects
  }, [objects])

  const fetchObject = (id: IObject['id'], options?: { isInitialFetching: boolean }) => {
    setIsLoading(true)

    return HttpService.get(`graph/${id}/`)
      .then((res: { data: IObject }) => {
        const object = {
          ...res.data,
          id,
          SubObjects: res.data.SubObjects?.map(subObject => ({ ...subObject, id: nanoid() }))
        }

        if (options?.isInitialFetching) {
          dispatch(objectPageSlice.actions.setObjects([object]))
        } else {
          dispatch(objectPageSlice.actions.addObject(object))
        }

        return object
      })
      .catch(e => {
        if (!e.isHandled) {
          message.error({
            content: `An error occurred while fetching the object: ${JSON.stringify(
              e.message || e
            )}`
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  const reloadRelations: TObjectForm['reloadRelations'] = id => {
    return HttpService.get(`graph/${id}/`)
      .then((res: { data: IObject }) => {
        dispatch(
          objectPageSlice.actions.updateObject({
            id,
            changes: {
              Relations: res.data.Relations
            }
          })
        )

        return res.data.Relations
      })
      .catch(e => {
        if (!e.isHandled) {
          message.error({
            content: `An error occurred while reloading the relations: ${JSON.stringify(
              e.message || e
            )}`
          })
        }
      })
  }

  const reloadRelationsIfTwoObjects = () => {
    if (objectsRef.current.length < 2) return Promise.resolve()

    const objectIdToReload = objectsRef.current.find(object => object.id !== activeObjectId)?.id

    if (objectIdToReload) {
      return reloadRelations(objectIdToReload)
    }

    return Promise.resolve()
  }

  const updateObjectRelations: TObjectForm['updateObjectRelations'] = newRelations => {
    const object = objectsRef.current.find(object => object.id === activeObjectId)

    if (!object) return

    dispatch(
      objectPageSlice.actions.updateObject({
        id: activeObjectId,
        changes: { Relations: newRelations }
      })
    )
  }

  const openNewObject: TObjectForm['openNewObject'] = id => {
    setIsLoading(true)

    return fetchObject(id)
      .then(object => {
        if (object) dispatch(objectPageSlice.actions.setActiveObjectId(object.id))
      })
      .finally(() => setIsLoading(false))
  }

  const closeTab = (id: EntityId): void => {
    dispatch(objectPageSlice.actions.removeObject(id))

    const newActiveObjectId = objects.filter(object => object.id !== id)?.[0]?.id

    if (newActiveObjectId) {
      dispatch(objectPageSlice.actions.setActiveObjectId(newActiveObjectId))
    }
  }

  const onCopy: TObjectForm['onCopy'] = id => {
    setIsLoading(true)

    return HttpService.post(`graph/${id}/copy/`)
      .then(res => {
        const id = res.data?.id as IObject['id']

        return openNewObject(id)
      })
      .then(reloadRelationsIfTwoObjects)
      .catch(e => {
        if (!e.isHandled) {
          message.error({
            content: `An error occurred while copying: ${JSON.stringify(e.message || e)}`
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  const onSave = (): void => {
    setIsLoading(true)

    const requests = objects.map(({ id, ..._object }) => HttpService.put(`graph/${id}/`, _object))

    Promise.all(requests)
      .then(() => {
        const successMessage =
          objects.length > 1 ? 'Objects have been saved' : 'Object has been saved'

        message.success({ content: successMessage })

        reloadRelationsIfTwoObjects()
      })
      .catch(e => {
        if (!e.isHandled) {
          message.error({
            content: `An error occurred while saving: ${JSON.stringify(e.message || e)}`
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onDelete = (): void => {
    modal.warning({
      title: 'Are you sure you want to delete the object?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Please be aware that all the connection to this knowledge card will be invalid' +
        ' after deletion.',
      okText: 'Yes, delete the object',
      onOk() {
        setIsLoading(true)

        HttpService.delete(`graph/${activeObjectId}/`)
          .then(() => {
            message.success({ content: 'The object has been successfully deleted' })

            return reloadRelationsIfTwoObjects()
          })
          .then(() => closeTab(activeObjectId))
          .catch(e => {
            if (!e.isHandled) {
              message.error({
                content: `An error occurred while deleting the object: ${JSON.stringify(
                  e.message || e
                )}`
              })
            }
          })
          .finally(() => setIsLoading(false))
      }
    })
  }

  return {
    isLoading,
    onCopy,
    onSave,
    onDelete,
    reloadRelations,
    updateObjectRelations,
    openNewObject,
    closeTab
  }
}
