import { NavLink, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import logo from 'assets/img/logo.svg'
import { PATHS } from 'constants/routes'
import { Mail, StarPlus } from 'components/common/icons'
import './header.scss'

export const Header = () => {
  const navigate = useNavigate()

  return (
    <div className="header-home">
      <img src={logo} alt="" />
      <div className="header-home_tabs">
        <NavLink
          className={({ isActive }) =>
            classNames('header-home_tabs_tab', isActive && 'header-home_tabs_tab_active')
          }
          to={PATHS.launchpad.url}
        >
          Launchpad
        </NavLink>
        <div className="header-home_tabs_divider" />
        <div className={classNames('header-home_tabs_tab', 'disabled')}>
          <Mail /> Send Email
        </div>
        <div className={classNames('header-home_tabs_tab', 'disabled')}>
          <StarPlus />
          Save as Tile
        </div>
      </div>
      <ArrowLeftOutlined onClick={() => navigate(-1)} />
      <ArrowRightOutlined onClick={() => navigate(1)} />
    </div>
  )
}
