import { memo } from 'react'
import { parseCamelCase } from 'helpers/helpers'
import { Input, Option, Select, TextArea } from 'components/common'
import { EFieldTypes, TObjectField } from '../../../types/object.types'
import './object-field.scss'

type TProps = {
  field: TObjectField
  changeField: (key: string, value: string) => void
}

export const ObjectField = memo(
  ({ field, changeField }: TProps) => {
    let Component = null

    if (!field.editable) {
      Component = <span className="read-only-value">{field.value || '—'}</span>
    } else if (field.field_type === EFieldTypes.text || field.field_type === EFieldTypes.date) {
      Component = (
        <Input
          className={field.field_type.toLowerCase()}
          type={field.field_type === EFieldTypes.date ? 'date' : undefined}
          value={field.value || undefined}
          onChange={e => changeField(field.property_name, e.target.value)}
        />
      )
    } else if (field.field_type === EFieldTypes.combobox) {
      Component = (
        <Select
          className="combobox"
          value={field.value}
          style={{ width: field.size, maxWidth: field.size }}
          placeholder={field.placeholder}
          showSearch
          allowClear
          onChange={value => changeField(field.property_name, value)}
        >
          {field.options?.map(option => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      )
    } else if (field.field_type === EFieldTypes.textarea) {
      Component = (
        <TextArea
          className="textarea"
          value={field.value}
          placeholder={field.placeholder}
          rows={field.rows}
          maxLength={field.maxlength}
          autoSize={field.autogrow}
          onChange={e => changeField(field.property_name, e.target.value)}
        />
      )
    }

    if (!Component) {
      console.error('Unexpected field type:', field)

      return null
    }

    return (
      <div className="object-field">
        <div className="label">{parseCamelCase(field.property_name)}</div>
        <div className="value">{Component}</div>
      </div>
    )
  },
  (prevProps, nextProps) => prevProps.field.value === nextProps.field.value
)
