import { AuthWrapper } from 'components/authWrapper/authWrapper'
import { Button, Input } from 'components/common'
import { useRegistrationForm } from './hooks'
import './registration.scss'

export const RegisterPage = (): JSX.Element => {
  const {
    email,
    password,
    workspaceId,
    name,
    companyName,
    role,
    isEULAChecked,
    emailError,
    passwordError,
    nameError,
    companyNameError,
    signUpError,
    setEmail,
    setPassword,
    setWorkspaceId,
    setName,
    setCompanyName,
    setRole,
    setIsEULAChecked,
    signUp
  } = useRegistrationForm()

  return (
    <AuthWrapper>
      <div className="register-page">
        <div className="content_register-form content-form">
          <span className="content-form-title">Get Started Today</span>
          <div className="sign-up-error">{signUpError}</div>
          <form name="RegisterForm" onSubmit={signUp}>
            <div className="form-inputs">
              <Input
                label="Email"
                value={email}
                error={!!emailError}
                errorText={emailError}
                className="form-input"
                placeholder="email"
                onChange={e => setEmail(e.target.value)}
                required
              />
              <Input
                label="Password"
                value={password}
                type="password"
                error={!!passwordError}
                errorText={passwordError}
                className="form-input"
                placeholder="password 7 + 1 upper case + 1 number"
                onChange={e => setPassword(e.target.value)}
                required
              />
              <Input
                label="Workspace ID"
                value={workspaceId}
                className="form-input"
                placeholder="workspace id"
                onChange={e => setWorkspaceId(e.target.value)}
              />
              <Input
                label="Name"
                value={name}
                error={!!nameError}
                errorText={nameError}
                className="form-input"
                placeholder="first name, last name"
                onChange={e => setName(e.target.value)}
                required
              />
              <Input
                label="Company Name"
                value={companyName}
                error={!!companyNameError}
                errorText={companyNameError}
                className="form-input"
                placeholder="company name"
                onChange={e => setCompanyName(e.target.value)}
                required
              />
              <Input
                label="Role"
                value={role}
                className="form-input"
                placeholder="role or title"
                onChange={e => setRole(e.target.value)}
              />
            </div>
            <div className="checkbox-wrapper">
              <input
                id="checkbox"
                className="checkbox"
                type="checkbox"
                checked={isEULAChecked}
                onChange={e => setIsEULAChecked(e.target.checked)}
              />
              <label className="checkbox-label" htmlFor="checkbox" />
              <span>
                <a
                  href="https://rock15.com/digital-brain-terms-and-privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  I agree to Terms and Privacy Policy
                </a>
              </span>
            </div>
            <div className="footer">
              <Button
                className="registration-button"
                type="primary"
                htmlType="submit"
                title={!isEULAChecked ? 'Please accept our Terms and Privacy Policy' : ''}
                disabled={!isEULAChecked}
                onClick={signUp}
              >
                Get Started Now
              </Button>
            </div>
          </form>
        </div>
      </div>
    </AuthWrapper>
  )
}
