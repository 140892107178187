import { FormEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { emailRegexp, passwordRegexp } from 'constants/validationRegexps'
import { PATHS } from 'constants/routes'
import { HttpService } from 'services/http.service'
import { message } from 'components/common'

const DEFAULT_SIGN_UP_ERROR_MESSAGE =
  'Unknown error occurred while performing sign up. Please try again.'

type UseRegistrationForm = {
  email: string
  password: string
  workspaceId: string
  name: string
  companyName: string
  role: string
  isEULAChecked: boolean
  emailError: string
  passwordError: string
  nameError: string
  companyNameError: string
  signUpError: string
  setEmail: (value: string) => void
  setPassword: (value: string) => void
  setWorkspaceId: (value: string) => void
  setName: (value: string) => void
  setCompanyName: (value: string) => void
  setRole: (value: string) => void
  setIsEULAChecked: (value: boolean) => void
  signUp: (e: FormEvent) => void
}

export const useRegistrationForm = (): UseRegistrationForm => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [workspaceId, setWorkspaceId] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [isEULAChecked, setIsEULAChecked] = useState<boolean>(true)
  const [emailError, setEmailError] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [companyNameError, setCompanyNameError] = useState<string>('')
  const [signUpError, setSignUpError] = useState<string>('')

  const navigate = useNavigate()

  const validateForm = useCallback(
    (email: string, password: string, name: string, companyName: string): boolean => {
      setEmailError('')
      setPasswordError('')
      setNameError('')
      setNameError('')
      setCompanyNameError('')

      let isFormValid = true

      if (!email || !emailRegexp.test(email)) {
        setEmailError('Please enter valid email')
        isFormValid = false
      }

      if (!password) {
        setPasswordError('Please enter valid password')
        isFormValid = false
      } else if (!passwordRegexp.test(password)) {
        setPasswordError(
          'The password must consist of minimum 8 characters, 1 uppercase letter and 1 number'
        )
        isFormValid = false
      }

      if (!name) {
        setNameError('Please enter valid name')
        isFormValid = false
      }

      if (!companyName) {
        setCompanyNameError('Please enter valid company name')
        isFormValid = false
      }

      return isFormValid
    },
    []
  )

  const signUp = useCallback(
    (e: FormEvent): void => {
      e.preventDefault()

      const isFormValid = validateForm(email, password, name, companyName)

      if (isFormValid) {
        HttpService.post('/auth/register/', {
          email,
          password,
          workspace: workspaceId,
          name,
          company: companyName,
          role
        })
          .then(() => {
            message.success({ content: 'Your account has been created. You can login now.' })

            navigate(PATHS.login.url, { state: { email } as { email: string } })
          })
          .catch((error: AxiosError<{ non_field_errors?: string[] }>) => {
            setSignUpError(
              error.response?.data?.non_field_errors?.[0] || DEFAULT_SIGN_UP_ERROR_MESSAGE
            )
          })
      }
    },
    [email, password, workspaceId, name, companyName, role, validateForm, navigate]
  )

  return {
    email,
    password,
    workspaceId,
    name,
    companyName,
    role,
    isEULAChecked,
    emailError,
    passwordError,
    nameError,
    companyNameError,
    signUpError,
    setEmail,
    setPassword,
    setWorkspaceId,
    setName,
    setCompanyName,
    setRole,
    setIsEULAChecked,
    signUp
  }
}
