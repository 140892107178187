import { matchPath } from 'react-router-dom'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { PATHS, PROTECTED_ROUTES_LIST } from 'constants/routes'
import { message } from 'components/common'

class _HttpService {
  private axios: AxiosInstance
  private readonly protectedRoutes: string[]

  constructor() {
    this.axios = axios.create({
      baseURL:
        window.location.hostname === 'localhost'
          ? 'https://digitalbraindemo.rock15.com/api'
          : '/api'
    })

    this.axios.defaults.withCredentials = true

    this.protectedRoutes = PROTECTED_ROUTES_LIST.map(route => route.routerPath)
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.axios.get(url, config).catch(this.handleError)
  }

  post(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.axios.post(url, data, config).catch(this.handleError)
  }

  put(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.axios.put(url, data, config).catch(this.handleError)
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.axios.delete(url, config).catch(this.handleError)
  }

  handleError = (error: AxiosError) => {
    const status = error.response?.status

    if (status === 401 || status === 403) {
      const isProtectedPage = this.protectedRoutes.some(route =>
        matchPath(window.location.pathname, route)
      )

      if (!isProtectedPage) return Promise.reject({ ...error, isHandled: true })

      message.info({ content: 'You are not authenticated. Please log in.' })

      const isObjectPath = matchPath(window.location.pathname, PATHS.object.routerPath)

      if (!isObjectPath) {
        window.navigate(PATHS.login.url, { state: { prevURL: window.location.pathname } })
      }

      return Promise.reject({ ...error, isHandled: true })
    }

    return Promise.reject(error)
  }
}

export const HttpService = new _HttpService()
