import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

type User = {
  email: string
  activeWorkspace: string
  isLoading: boolean
  isError: boolean
}

type Workspace = {
  uuid: string
  name: string
  description: string
}

export interface CommonState {
  user: User | null
  workspaces: Workspace[] | null
}

const initialState: CommonState = {
  user: null,
  workspaces: null
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ email: string; active_workspace: string }>) => {
      state.user = {
        email: action.payload.email,
        activeWorkspace: action.payload.active_workspace,
        isLoading: false,
        isError: false
      }
    },
    setIsUserLoading: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.user.isLoading = action.payload
      }
    },
    setIsUserError: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.user.isError = action.payload
      }
    },
    setActiveWorkspace: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.activeWorkspace = action.payload
      }
    },
    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.workspaces = action.payload
    },
    resetCommonSlice: state => {
      state.user = initialState.user
      state.workspaces = initialState.workspaces
    }
  }
})

export const getCommonSlice = (state: RootState) => state.common

export const getUser = (state: RootState) => getCommonSlice(state).user
export const getWorkspaces = (state: RootState) => getCommonSlice(state).workspaces
