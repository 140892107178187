import { EntityId } from '@reduxjs/toolkit'

export enum EFieldTypes {
  text = 'text',
  textarea = 'textarea',
  date = 'Date',
  combobox = 'combobox'
}

export interface IGenericField {
  default_value: string
  property_name: 'ModelLabel'
  hidden: boolean
  editable: boolean
}

export interface IInputField extends IGenericField {
  field_type: EFieldTypes.text
  value?: string | Array<string>
}

export interface ITextareaField extends IGenericField {
  field_type: EFieldTypes.textarea
  value?: string | Array<string>
  rows?: number
  cols?: number
  maxlength?: number
  autogrow?: boolean
  placeholder?: string
}

export interface IDateField extends IGenericField {
  field_type: EFieldTypes.date
  value?: string
}

export interface IComboboxField extends IGenericField {
  field_type: EFieldTypes.combobox
  value?: string
  options?: Array<string | undefined>
  size?: number
  placeholder?: string
}

export enum ERelationTypes {
  Outbound = 'Outbound',
  Inbound = 'Inbound'
}

export interface IRelationObject {
  id: EntityId
  type: string
  Display_title_of: string
  ShortDescription: string
  LongDescription: string
  Property: string
}

export interface IRelation {
  Property: string
  ObjectTypes: Array<string>
  Objects: Array<IRelationObject>
}

export type TRelations = {
  [key in ERelationTypes]?: Array<IRelation>
}

export type TSubObject = IObject & {
  Multiple?: boolean
}

export type TObjectField = IInputField | ITextareaField | IDateField | IComboboxField

export interface IObject {
  id: EntityId
  Category: string
  Template: string
  Fields: {
    [key: string]: TObjectField
  }
  Hidden?: Array<string>
  ReadOnly?: Array<string>
  Relations?: TRelations
  SubObjects?: Array<TSubObject>
  SubObjectTemplates?: Array<TSubObject>
  isReadOnly?: boolean
}
