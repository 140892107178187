import { ReactNode } from 'react'
import './itemDrag.scss'

type TProps = {
  title: ReactNode
  name: ReactNode
  img: string
  redirect: string
}

export const ItemDrag = ({ title, name, img, redirect }: TProps) => (
  <div className="item-home-drag" onClick={() => window.open(redirect)}>
    <div className="item-home-drag_title">{title}</div>
    <div className="item-home-drag_name">{name}</div>
    <img className="item-home-drag_name_img" src={img} alt="" />
  </div>
)
