import { createEntityAdapter, createSlice, EntityId, EntityState } from '@reduxjs/toolkit'
import { IObject } from './types/object.types'

export interface IObjectPageSlice {
  objects: EntityState<IObject>
  activeObjectId: EntityId
  isReadOnlyMode: boolean
  isLightReadOnlyMode: boolean
}

export const objectsAdapter = createEntityAdapter<IObject>({
  selectId: object => object.id
})

const initialState = {
  objects: objectsAdapter.getInitialState(),
  activeObjectId: '',
  isReadOnlyMode: false
} as IObjectPageSlice

export const objectPageSlice = createSlice({
  name: 'objectPage',
  initialState,
  reducers: {
    setObjects: (state, action) => {
      objectsAdapter.setAll(state.objects, action.payload)
    },
    addObject: (state, action) => {
      objectsAdapter.addOne(state.objects, action.payload)
    },
    updateObject: (state, action) => {
      objectsAdapter.updateOne(state.objects, action.payload)
    },
    removeObject: (state, action) => {
      objectsAdapter.removeOne(state.objects, action.payload)
    },
    setActiveObjectId: (state, action: { payload: IObject['id'] }) => {
      state.activeObjectId = action.payload
    },
    setIsReadOnlyMode: (state, action: { payload: IObjectPageSlice['isReadOnlyMode'] }) => {
      state.isReadOnlyMode = action.payload
    },
    setIsLightReadOnlyMode: (
      state,
      action: { payload: IObjectPageSlice['isLightReadOnlyMode'] }
    ) => {
      state.isLightReadOnlyMode = action.payload
    }
  }
})
