import { useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ModalProps } from 'antd/lib/modal'
import { TransferItem } from 'antd/es/transfer'
import { HttpService } from 'services/http.service'
import { parseCamelCase } from 'helpers/helpers'
import { useValueRef } from 'hooks/useValueRef'
import { Modal, Transfer, Loader, message } from 'components/common'
import { ERelationTypes, IRelation, IRelationObject, TRelations } from '../../../types/object.types'
import { objectsPageSelectors } from '../../../object.selectors'
import './addRelationsModal.scss'

type TAddRelationsModal = {
  type: keyof typeof ERelationTypes
  relation: IRelation | null | undefined
  relations: TRelations
  isOpen: ModalProps['open']
  updateRelations: (relations: TRelations) => void
  close: ModalProps['onCancel']
}

enum ETitles {
  Source = 'Potential Relations',
  Target = 'Related Objects'
}

const parseRelations = (relations: Array<IRelationObject>): Array<TransferItem> =>
  relations.map(relation => ({
    key: String(relation.id),
    title: relation.ShortDescription,
    description: relation.LongDescription
  }))

export const AddRelationsModal = ({
  type,
  relation,
  relations,
  isOpen,
  updateRelations,
  close
}: TAddRelationsModal) => {
  const [allRelations, setAllRelations] = useState<IRelationObject[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { activeObjectId } = useSelector(objectsPageSelectors.getObjectPageSlice)

  const selectedRelations = relation?.Objects

  const selectedRelationsRef = useValueRef(selectedRelations)

  useLayoutEffect(() => {
    if (!isOpen || !activeObjectId) return

    setIsLoading(true)

    HttpService.get(`graph/${activeObjectId}/potential_relations/`)
      .then(res => {
        const relations = res.data?.Relations as TRelations

        if (!relations) return

        const potentialRelations = relations[type]?.find(
          (_relation: IRelation) => _relation.Property === relation?.Property
        ) as IRelation

        setAllRelations([
          ...(potentialRelations?.Objects || []),
          ...(selectedRelationsRef.current || [])
        ])
      })
      .catch(e => {
        if (!e.isHandled) {
          message.error({
            content: `An error occurred while fetching potential relations: ${JSON.stringify(
              e.message || e
            )}`
          })
        }
      })
      .finally(() => setIsLoading(false))
  }, [isOpen, activeObjectId, type, relation?.Property, selectedRelationsRef])

  const parsedAllRelations = useMemo(() => parseRelations(allRelations), [allRelations])
  const selectedRelationsKeys = useMemo(
    () => selectedRelations?.map(relation => String(relation.id)) || [],
    [selectedRelations]
  )

  const changeSelectedRelations = (relationKeys: TransferItem['key'][]) => {
    const newSelectedRelations = relationKeys.reduce((result, key) => {
      const object = allRelations.find(relation => relation.id === key)

      if (object) result.push(object)

      return result
    }, [] as Array<IRelationObject>)

    const newRelations = relations[type]?.reduce((result, relationObject) => {
      if (relationObject.Property === relation?.Property) {
        result.push({ ...relation, Objects: newSelectedRelations })
      } else {
        result.push(relationObject)
      }

      return result
    }, [] as Array<IRelation>)

    updateRelations({ ...relations, [type]: newRelations })
  }

  const modalContent = isLoading ? (
    <Loader size="large" />
  ) : (
    <Transfer
      dataSource={parsedAllRelations}
      titles={[ETitles.Source, ETitles.Target]}
      targetKeys={selectedRelationsKeys}
      render={relation => relation.title || ''}
      showSearch
      onChange={changeSelectedRelations}
    />
  )

  return (
    <Modal
      className="add-relations-modal"
      title={`Add/Remove Objects to [${parseCamelCase(relation?.Property)}] Relation`}
      width="80%"
      open={isOpen}
      closable={false}
      confirmLoading={isLoading}
      onCancel={close}
      onOk={close}
    >
      {modalContent}
    </Modal>
  )
}
