import { StrictMode, useLayoutEffect, ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import { store } from 'store/store'
import { PATHS } from 'constants/routes'
import { LoginPage, RegisterPage, ObjectPage } from 'pages'
import { message } from 'components/common'
import { commonSlice } from 'store/commonSlice'
import { HttpService } from 'services/http.service'
import { Header } from 'components/header/header'
import { LaunchPad } from 'pages/launchPad/launchPad'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import 'antd/dist/antd.css'

const AppWrapper = ({ children }: { children: ReactNode }): JSX.Element => {
  window.navigate = useNavigate()

  return <>{children}</>
}

const App = (): JSX.Element => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(commonSlice.actions.setIsUserLoading(true))
    dispatch(commonSlice.actions.setIsUserError(false))

    HttpService.get('/users/profile/')
      .then(res => {
        dispatch(commonSlice.actions.setUser(res.data))
      })
      .catch(e => {
        dispatch(commonSlice.actions.setIsUserError(true))

        if (!e.isHandled) {
          message.error({
            content: 'An error occurred while fetching user profile. Try to reload the page.'
          })
        }
      })
      .finally(() => dispatch(commonSlice.actions.setIsUserLoading(false)))
  }, [dispatch])

  return (
    <>
      <Header />
      <Routes>
        <Route path={PATHS.launchpad.routerPath} element={<LaunchPad />} />
        <Route path="*" element={<Navigate to={PATHS.launchpad.routerPath} replace />} />
      </Routes>
    </>
  )
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <AppWrapper>
            <Routes>
              <Route path={PATHS.login.routerPath} element={<LoginPage />} />
              <Route path={PATHS.signUp.routerPath} element={<RegisterPage />} />
              <Route path={PATHS.object.routerPath} element={<ObjectPage />} />
              <Route path="*" element={<App />} />
            </Routes>
          </AppWrapper>
        </BrowserRouter>
      </Provider>
    </StrictMode>
  )

  serviceWorkerRegistration.register()
}
