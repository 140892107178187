import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ObjectWrapper } from './components/objectWrapper'
import { ObjectHeader } from './components/header/header'
import { ObjectFooter } from './components/footer/footer'
import { useObjectForm } from './hooks/useObjectForm'
import { Attachments } from './components/attachments/attachments'
import Relations from './components/relations/relations'
import { SubObjects } from './components/subobjects/subObjects'
import { ObjectFields } from './components/objectFields/objectFields'
import { objectsPageSelectors } from './object.selectors'
import './object.scss'

export const ObjectPage = (): JSX.Element => {
  const { id = '' } = useParams()

  const activeObject = useSelector(objectsPageSelectors.getActiveObject)

  const {
    isLoading,
    onCopy,
    onSave,
    onDelete,
    reloadRelations,
    updateObjectRelations,
    openNewObject,
    closeTab
  } = useObjectForm(id)

  return (
    <ObjectWrapper isLoading={isLoading}>
      <ObjectHeader closeTab={closeTab} />
      <div className="object-content">
        {!!activeObject && (
          <>
            <ObjectFields object={activeObject} />
            <Attachments object={activeObject} />
            {!!activeObject.Relations && (
              <Relations
                objectId={activeObject.id}
                relations={activeObject.Relations}
                reloadRelations={reloadRelations}
                openNewObject={openNewObject}
                updateRelations={updateObjectRelations}
                onCopy={onCopy}
              />
            )}
            <SubObjects object={activeObject} openNewObject={openNewObject} onCopy={onCopy} />
          </>
        )}
      </div>
      <ObjectFooter onCopy={onCopy} onDelete={onDelete} onSave={onSave} />
    </ObjectWrapper>
  )
}
